body {
    background-color: #f8fbfc;

    .hero-single {
        max-width: 100vw;
        height: 300px;
        background-repeat: no-repeat;

        &.elaphiti {
            background-image: url(images/priroda-hero.jpg);
            background-position: 50% 70%; }

        &.dubrovnik {
            background-image: url(images/dubrovnik-hero.jpg);
            background-position: 55% 50%; }

        &.ston {
            background-image: url(images/Ston.jpg);
            background-position: 50% 30%; } }

    .big-map {
        min-width: 99vw;
        max-width: 100vw;
        display: none; }

    .small-map {
        .map {
            display: block; } }

    .wrapper {
        width: 90%;
        margin: 0 auto;

        h1 {
            font-family: Signika;
            text-transform: uppercase;
            font-size: 25px;
            background-color: #f8fbfc;
            margin-top: -60px;
            padding: 20px 40px;
            border-radius: 10px;
            color: #1f1e1e;

            &.elaphiti {
                width: 320px; }

            &.dubrovnik {
                width: 320px; }

            &.ston {
                width: 300px; } }

        .line::before {
          content: "slano";
          padding-right: 80px;
          font-size: 2.5px;
          color: rgb(104,104,106);
          background-color: rgb(104,104,106); }

        .flex {
            display: flex;
            flex-direction: column;
            margin-top: 30px;

            h2 {
                font-family: 'Oswald', sans-serif;
                text-transform: uppercase;
                font-size: 25px;
                color: #313131; }

            .description {
                width: 100%;

                p:first-of-type {
                    padding-top: 30px; }

                p {
                    padding-top: 10px;
                    color: #313131;
                    font-size: 19px;
                    font-family: Roboto;

                    a {
                        color: #1e70a3;
                        text-decoration: none; } } }

            .info {
                width: 100%;
                margin-top: 40px;
                line-height: 2; } } }

    .map {
        max-width: 100%;
        margin-top: 90px;
        min-width: 100%; }

    .gallery {
        display: flex;
        flex-wrap: wrap;
        margin: 70px auto;
        justify-content: center;
        width: 100%;

        div {
            display: flex;
            flex-wrap: wrap;
            margin: 70px auto;
            justify-content: center;
            width: 100%; }

        .image-link {
            overflow: hidden;

            .image {
                width: 180px;
                height: 130px;
                padding: 5px;
                transition: 0.5s all;

                &:hover {
                    transform: scale(1.2); } } } } }


@media screen and ( min-width: 768px) {
    body {
        .hero-single {
            height: 450px;

            &.elaphiti {
                background-position: 50% 60%; } }


        .big-map {
            display: block; }

        .small-map {
            .map {
                display: none; } }

        .wrapper {
            width: 80%;

            h1 {
                font-size: 40px;
                margin-top: -70px;

                &.elaphiti {
                    width: 600px; }

                &.dubrovnik {
                    width: 480px; }

                &.ston {
                    width: 420px; } }

            .flex {
                flex-direction: row;
                margin-top: 70px;

                .description {
                    padding-left: 40px;
                    width: 50%;
                    // padding-right: 80px

                    p:first-of-type {
                        padding-top: 30px; } }

                .info {
                    width: 40%;
                    margin-top: 0px;
                    display: flex;
                    margin-left: 100px;

                    h2 {
                        margin-left: 60px; } } } } }

    .gallery {
        width: 80% !important;

        .image-link {
            .image {
                width: 260px !important;
                height: 200px !important;
                padding: 8px !important; }

            .map {
                max-width: 100vw;
                min-width: 99vw;
                margin-top: 90px; } } } }


